import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    background: '#FFF',
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 999999999,
    borderRadius: 16,
  },
  button: {
    background: '#FFF',
    padding: '10px 20px',
    borderRadius: 16,
    boxShadow: `0 1px 1px rgba(0, 0, 0, 0.04), 
    0 2px 2px rgba(0, 0, 0, 0.04), 
    0 4px 4px rgba(0, 0, 0, 0.04), 
    0 8px 8px rgba(0, 0, 0, 0.04),
    0 16px 16px rgba(0, 0, 0, 0.04)`,
    color: '#AAA',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 25,
    marginRight: 5,
  },
}));
