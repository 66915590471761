import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from 'theme';
import Routes from 'routes';
import ButtonChangeLanguage from 'components/button-change-language';
import Loading from 'pages/loading';
import ResponseHandler from 'utils/response-handler';
import { GlobalProvider } from 'contexts/global';
import NotistackProvider from 'components/notistack-provider';
import GlobalStyles from 'theme/global-styles';
import RenderError from 'pages/errors/render-error';
import { GOOGLE_AUTH_CLIENT_ID } from 'constants/config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'config/i18n';
import 'utils/string';
import 'utils/number';

function App() {
  if (window?.location !== window?.parent?.location) {
    return <p>access via iframe is not allowed !</p>;
  } else {
    return (
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
        <ThemeProvider>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <GlobalProvider>
                <NotistackProvider>
                  <ResponseHandler>
                    <GlobalStyles />
                    <ErrorBoundary FallbackComponent={RenderError}>
                      <Routes />
                    </ErrorBoundary>
                    <ButtonChangeLanguage />
                  </ResponseHandler>
                </NotistackProvider>
              </GlobalProvider>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
