import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

const NotFound = lazy(() => import('pages/errors/not-found'));
const Landing = lazy(() => import('pages/landing'));

const Register = lazy(() => import('pages/registration/register'));
const SelectCustomerType = lazy(() =>
  import('pages/registration/select-customer-type')
);
const RegisterIndividu = lazy(() =>
  import('pages/registration/register-individu')
);
const RegisterCompany = lazy(() =>
  import('pages/registration/register-company')
);

const PartnerPortal = lazy(() => import('pages/partner-portal'));
const AksesTokoPortal = lazy(() => import('pages/partner-portal/akses-toko'));
const NotAksesTokoPortal = lazy(() =>
  import('pages/partner-portal/not-akses-toko')
);

const PartnerLogin = lazy(() => import('pages/partner-login'));

const UploadDocument = lazy(() => import('pages/upload-document'));

const Otp = lazy(() => import('pages/registration/otp'));
const SendLink = lazy(() =>
  import('pages/registration/forgot-password/send-link')
);
const ResetPassword = lazy(() =>
  import('pages/registration/forgot-password/reset-password')
);

const AksesToko = lazy(() => import('pages/aksestoko'));
const Login = lazy(() => import('pages/common/login'));

const PartnerWebLinkTemplate = lazy(() => import('templates/partner-web-link'));
const PartnershipTemplate = lazy(() => import('templates/partnership'));
const LenderTemplate = lazy(() => import('templates/lender'));
const BorrowerTemplate = lazy(() => import('templates/borrower'));
const AdminTemplate = lazy(() => import('templates/admin'));

const ESignOTP = lazy(() => import('pages/e-sign-otp'));
const PksOTP = lazy(() => import('pages/pks-otp'));

export const routes = [
  {
    path: '/',
    component: Landing,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/login-internal',
    component: Login,
    exact: true,
  },
  {
    path: '/registration',
    component: Register,
    exact: true,
  },
  {
    path: '/registration/select-customer-type',
    component: SelectCustomerType,
    exact: true,
  },
  {
    path: '/registration/individual',
    component: RegisterIndividu,
    exact: true,
  },
  {
    path: '/registration/company',
    component: RegisterCompany,
    exact: true,
  },
  {
    path: '/registration/otp',
    component: Otp,
    exact: true,
  },
  {
    path: '/upload-document',
    component: UploadDocument,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: SendLink,
    exact: true,
  },
  {
    path: '/forgot-password/:token',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/e-sign/otp/:id/:token',
    component: ESignOTP,
    exact: true,
  },
  {
    path: '/e-sign/otp/pks/:id/:token',
    component: PksOTP,
    exact: true,
  },
  {
    path: '/lender',
    component: LenderTemplate,
    exact: false,
  },
  {
    path: '/borrower',
    component: BorrowerTemplate,
    exact: false,
  },
  {
    path: '/admin',
    component: AdminTemplate,
    exact: false,
  },
  {
    path: '/partnership',
    component: PartnershipTemplate,
    exact: false,
  },
  {
    path: '/partner',
    component: PartnerWebLinkTemplate,
    exact: false,
  },
  {
    path: '/partner-portal',
    component: PartnerPortal,
    exact: true,
  },
  {
    path: '/partner-portal/akses-toko',
    component: AksesTokoPortal,
    exact: true,
  },
  {
    path: '/partner-portal/not-akses-toko',
    component: NotAksesTokoPortal,
    exacta: true,
  },
  {
    path: '/partner-login',
    component: PartnerLogin,
    exact: true,
  },
  {
    path: '/aksestoko',
    component: AksesToko,
    exact: true,
  },
];

export default function Routes() {
  React.useEffect(() => {
    if (document.location.origin == 'http://lms.kreditpro.id') {
      window.location.href = process.env.REACT_APP_FE_PRODUCTION_BASE_URL;
    }
  }, []);

  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          key={key}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}
