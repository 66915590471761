import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#6f1c6c',
  },
  center: {
    textAlign: 'center',
  },
  img: {
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  errorWrapper: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(6),
    borderTop: '4px dashed rgba(255,255,255,0.1)',
  },
  message: {
    fontWeight: 'bold',
    textAlign: 'left',
    color: theme.palette.error.main,
  },
  stackWrapper: {
    height: 150,
    overflow: 'auto',
  },
  stack: {
    color: 'rgba(255,255,255,0.5)',
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));
