String.prototype.toCapitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.x100 = function () {
  return '';
};

export function getShortFileName({ text, maxLength }) {
  if (typeof text == 'string') {
    const fileName = text?.split('.')[0];
    const extension = text?.split('.')[text.split('.').length - 1];

    if (fileName?.length <= maxLength) {
      return text;
    } else {
      return `${fileName?.slice(0, maxLength / 2)}...${text
        ?.split('.')[0]
        ?.slice(-(maxLength / 2))}.${extension}`;
    }
  } else {
    return text;
  }
}

export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
