import printValue from 'config/yup/utils/print-value.js';

export const errors_id = {
  mixed: {
    default: '${path} tidak valid',
    required: '${path} adalah field yang harus diisi',
    oneOf: '${path} harus salah satu dari nilai berikut: ${values}',
    notOneOf: '${path} tidak boleh salah satu dari nilai berikut: ${values}',
    notType: ({ path, type, value, originalValue }) => {
      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `${path} harus menjadi \`${type}\` tipe, ` +
        `tapi nilai akhirnya adalah: \`${printValue(value, true)}\`` +
        (isCast
          ? ` (cast dari nilai \`${printValue(originalValue, true)}\`).`
          : '.');

      if (value === null) {
        msg += `\n Jika "null" dimaksudkan sebagai nilai kosong, pastikan untuk menandai skema sebagai \`.nullable()\``;
      }

      return msg;
    },
    defined: '${path} harus didefinisikann',
  },

  string: {
    length: '${path} harus persis ${length} karakter',
    min: '${path} setidaknya harus ${min} Karakter',
    max: '${path} harus paling banyak ${max} karakter',
    matches: '${path} harus sesuai dengan yang berikut ini: "${regex}"',
    email: '${path} harus berupa email yang valid',
    url: '${path} harus berupa URL yang valid',
    uuid: '${path} harus UUID yang valid',
    trim: '${path} harus berupa string yang dipangkas',
    lowercase: '${path} harus berupa string huruf kecil',
    uppercase: '${path} harus berupa string huruf besar',
  },
  number: {
    min: '${path} harus lebih besar dari atau sama dengan ${min}',
    max: '${path} harus kurang dari atau sama dengan ${max}',
    lessThan: '${path} harus kurang dari ${less}',
    moreThan: '${path} harus lebih besar dari ${more}',
    positive: '${path} harus bilangan positif',
    negative: '${path} harus berupa angka negatif',
    integer: '${path} harus berupa bilangan bulat',
  },
  date: {
    min: '${path} field harus lebih dari ${min}',
    max: '${path} field harus lebih awal dari ${max}',
  },
  boolean: {
    isValue: '${path} field harus ${value}',
  },
  object: {
    noUnknown: '${path} field memiliki kunci yang tidak ditentukan: ${unknown}',
  },
  array: {
    min: '${path} field harus memiliki setidaknya ${min} item',
    max: '${path} field harus kurang dari atau sama dengan ${max} item',
    length: '${path} harus punya ${length} item',
  },
};
