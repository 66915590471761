import React from 'react';
import { axiosInstance } from 'config/axiosInstance';
import ErrorDialog from 'utils/response-handler/error-dialog';
import SuccessDialog from 'utils/response-handler/success-dialog';
import { useTranslation } from 'react-i18next';
import useNotification from 'hooks/use-notification';
import { getTokenKey, getProfileKey } from 'utils';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { getRole } from 'utils';

function ResponseHandler({ children }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = React.useState({
    open: false,
    message: t('server_error'),
  });
  const [success, setSuccess] = React.useState({
    open: false,
    message: t('no_message'),
  });

  const { sendError } = useNotification();

  React.useEffect(() => {
    axiosInstance.interceptors.response.use(
      function (response) {
        console.log(response);
        if (response?.success === false) {
          setError({
            open: true,
            message: response?.message,
          });
        } else if (response?.status === 207) {
          setSuccess({
            open: true,
            message: response?.message,
          });
        } else {
          return response;
        }
      },
      function (error) {
        if (!error.response) {
          // NETWORK ERROR
          sendError(t('network_error'));
        } else {
          switch (error?.response?.status) {
            case 401:
              switch (error?.response?.data?.error?.type) {
                case 'ROLE/PERMISSION DENIED':
                  history.push(`/${getRole()}`);
                  sendError(t('doesnt_have_permission_to_this_route'));
                  break;
                case 'INVALID_TOKEN':
                  history.push('/login');
                  // localStorage.setItem('redirect_url', window.location.href);
                  sendError(t('invalid_token'));
                  localStorage.removeItem(getProfileKey());
                  Cookies.remove(getTokenKey());
                  break;
                case 'SESSION_EXPIRED':
                  history.push(`/login`);
                  // localStorage.setItem('redirect_url', window.location.href);
                  sendError(t('session_expired'));
                  localStorage.removeItem(getProfileKey());
                  Cookies.remove(getTokenKey());
                  break;
                default:
                  history.push(`/${getRole()}`);
                  sendError(error?.response?.data?.error?.type);
              }
              break;
            case 500:
              setError({
                open: true,
                message: t('server_error'),
              });
              break;
            case 422:
              // eslint-disable-next-line no-case-declarations
              const errors = error?.response?.data?.error?.data;
              errors.forEach((err) => {
                sendError(err.message);
              });
              break;
            case 400:
              if (error?.response?.data?.message !== 'duplicate found') {
                sendError(error?.response?.data?.message);
              }
              break;
            case 202:
              break;
            case 207:
              break;
            case 208:
              break;
            case 404:
              if (error?.response?.data?.message) {
                sendError(error?.response?.data?.message);
              }
              break;
            default:
              setError({
                open: true,
                message: error?.response?.data?.message,
              });
              break;
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
      {children}
      <ErrorDialog error={error} setError={setError} />
      <SuccessDialog success={success} setSuccess={setSuccess} />
    </>
  );
}

export default ResponseHandler;
