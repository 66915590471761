export function search(items, keyword) {
  const result = items.filter((item) => {
    let found = false;
    for (let key in item) {
      if (item[key].toLowerCase().includes(keyword.toLowerCase())) {
        found = true;
        break;
      } else {
        found = false;
        break;
      }
    }
    return found;
  });

  return result;
}

export function extractErrors({ inner }) {
  return inner?.reduce((acc, err) => {
    return { ...acc, [err.path]: err.message };
  }, {});
}

export function formatRupiah(nominal) {
  var formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });
  return formatter.format(nominal);
}

export function uuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function getPath(signedUrl) {
  try {
    if (signedUrl === '') {
      return signedUrl;
    }
    const regExp = /com\/([^)]+)\?/;
    const matches = regExp.exec(signedUrl);

    return matches[1];
  } catch (err) {
    return signedUrl;
  }
}

export function getUrl(signedUrl) {
  try {
    if (signedUrl === '') {
      return signedUrl;
    }
    const regExp = /([^)]+)\?/;
    const matches = regExp.exec(signedUrl);

    return matches[1];
  } catch (err) {
    return signedUrl;
  }
}

export function cleanString(_input) {
  const input = _input ?? '';
  var output = '';
  for (var i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    }
  }
  return String(output.replaceAll('.', '').replaceAll('-', ''));
}

export function getTokenKey(role = '') {
  if (role == '') {
    return 'token_' + window.location.href.split('/')[3];
  } else {
    return 'token_' + role;
  }
}

export function getProfileKey(role = '') {
  if (role == '') {
    return 'profile_' + window.location.href.split('/')[3];
  } else {
    return 'profile_' + role;
  }
}

export function getUrlParam(partner = '') {
  if (partner == '') {
    return 'url_param_akses_toko';
  } else {
    return 'url_param_' + partner;
  }
}

export function getRole() {
  // borrower | lender | admin
  return window.location.href.split('/')[3];
}

export function chunkArray(array, chunkSize) {
  return [].concat.apply(
    [],
    array.map(function (elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    })
  );
}

export function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => ![null, '', undefined].includes(v))
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}
