export const table = (theme) => ({
  '.tableContainer': {},
  '.tablePaper': {
    borderRadius: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'auto',
  },
  '.appBar': {
    background: '#01604b',
  },
  '.tab': {
    height: 70,
    textTransform: 'uppercase',
  },
  '.wrapper': {
    overflow: 'auto',
  },
  '.table': {
    borderCollapse: 'collapse',
    width: '100%',
  },
  '.tr': {
    transition: '0.25s ease-out',
    '&:nth-of-type(even)': {
      background: 'rgba(0,0,0,0.05)',
    },
    '&:hover': {
      background: 'rgba(0,0,0,0.15)',
    },
  },
  '.th': {
    padding: '10px 20px',
    fontWeight: 'bold',
    textAlign: 'left',
    background: theme.palette.primary.main,
    color: '#FFF',
    textTransform: 'uppercase',
  },
  '.th_green': {
    padding: '10px 20px',
    fontWeight: 'bold',
    textAlign: 'left',
    background: 'rgb(25, 108, 64)',
    color: '#FFF',
    textTransform: 'uppercase',
  },
  '.td': {
    padding: '10px 20px',
  },
});
