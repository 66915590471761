import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import idIcon from 'assets/images/id.png';
import enIcon from 'assets/images/en.png';
import { languages } from 'constants/languages';
import { setLocale } from 'yup';
import { errors_id, errors_en } from 'config/yup/locales';

export default function ButtonChangeLanguage() {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const newLang = i18n.language === 'id' ? 'en' : 'id';
    i18n.changeLanguage(newLang);
  };

  React.useEffect(() => {
    setLocale(i18n.language == 'id' ? errors_id : errors_en);

    i18n.on('languageChanged', (newLang) => {
      setLocale(newLang == 'id' ? errors_id : errors_en);
    });
  }, []);

  return (
    <div className={classes.container}>
      <Button
        onClick={changeLanguage}
        className={classes.button}
        endIcon={<ImportExportIcon />}
      >
        <img
          src={i18n.language === 'id' ? idIcon : enIcon}
          alt="lang"
          className={classes.icon}
        />
        {languages &&
          languages.filter((l) => l.key === i18n.language)[0]?.label}
      </Button>
    </div>
  );
}
