export const base_url =
  document.location.origin === process.env.REACT_APP_FE_TESTING_BASE_URL
    ? process.env.REACT_APP_BE_TESTING_BASE_URL
    : document.location.origin === process.env.REACT_APP_FE_PRODUCTION_BASE_URL
    ? process.env.REACT_APP_BE_PRODUCTION_BASE_URL
    : document.location.origin === process.env.REACT_APP_FE_DRC_BASE_URL
    ? process.env.REACT_APP_BE_DRC_BASE_URL
    : process.env.REACT_APP_BE_DEV_BASE_URL;
// process.env.REACT_APP_BE_LOCAL_BASE_URL;

export const base_url_aksestoko =
  document.location.origin === process.env.REACT_APP_FE_PRODUCTION_BASE_URL ||
  document.location.origin === process.env.REACT_APP_FE_DRC_BASE_URL
    ? process.env.REACT_APP_AKSESTOKO_PRODUCTION_BASE_URL
    : process.env.REACT_APP_AKSESTOKO_QA_BASE_URL;
