import { createStyles, makeStyles } from '@material-ui/core';
import { statuses } from './statuses';
import { table } from './table';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      ...statuses(),
      ...table(theme),
      '.dashedLine': {
        border: 'none',
        borderBottom: '4px dashed #DDD',
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
