import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import RenderErrorIllustration from 'assets/images/render-error.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const RenderError = ({ error }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.wrapper}>
      <Grid container style={{ padding: 12 }}>
        <Grid item xs={12} container justify="center" className={classes.mt}>
          <Grid item xs={10} sm={8} md={6} lg={4}>
            <img
              src={RenderErrorIllustration}
              alt="Error"
              className={classes.img}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h4"
              className={clsx([classes.title, classes.center])}
            >
              {t('oops_an_error_occured')}
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={2} justify="center">
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                style={{ color: 'white', border: '1.5px solid white' }}
              >
                {t('back')}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8} className={classes.errorWrapper}>
            <Typography variant="h6" className={clsx([classes.message])}>
              {error.message}
            </Typography>
            <div className={classes.stackWrapper}>
              <pre className={classes.stack}>{error.stack}</pre>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderError;
