const baseStyle = {
  padding: '4px 12px',
  textTransform: 'uppercase',
  borderRadius: 4,
  whiteSpace: 'nowrap',
};

export const statuses = () => ({
  '.STATUS_ORANGE': {
    ...baseStyle,
    color: 'rgb(230, 128, 43)',
    background: 'rgba(230, 128, 43, 0.2)',
  },
  '.STATUS_GREEN': {
    ...baseStyle,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.STATUS_RED': {
    ...baseStyle,
    color: 'rgba(220, 0, 78, 1)',
    background: 'rgba(220, 0, 78, 0.2)',
  },
  '.STATUS_GREY': {
    ...baseStyle,
    color: 'rgba(0,0,0, 0.5)',
    background: 'rgba(0,0,0, 0.1)',
  },

  // ABU-ABU
  '.Pending': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(0,0,0, 0.5)',
    background: 'rgba(0,0,0, 0.1)',
  },

  // HIJAU
  '.Approved': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.Assigned_To_Lender': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.Accepted_By_Lender': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.Disbursed_From_Lender': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.Published_to_Marketplace': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },
  '.Active': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(27, 170, 86, 1)',
    background: 'rgba(27, 170, 86, 0.2)',
  },

  // KUNING
  '.Approved/Rejected': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgb(230, 128, 43)',
    background: 'rgba(230, 128, 43, 0.2)',
  },

  // MERAH
  '.Cancelled': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(220, 0, 78, 1)',
    background: 'rgba(220, 0, 78, 0.2)',
  },
  '.Rejected': {
    padding: '4px 12px',
    borderRadius: 4,
    color: 'rgba(220, 0, 78, 1)',
    background: 'rgba(220, 0, 78, 0.2)',
  },
});
